import { inject, Injectable } from '@angular/core';
import { User } from '../classes/user';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AlertsService } from './alerts.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Settings } from '../classes/settings';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  me: User;

  translateService: TranslateService = inject(TranslateService);
  alertService: AlertsService = inject(AlertsService);

  constructor(private httpClient: HttpClient, private router: Router) { }

  getUser(): User {
    return this.me;
  }

  login(username: string, password: string): Observable<any> {
    return this.httpClient.post<any>(Settings.LOGIN_URL, { username, password });
  }

  logout() {
    this.removeToken();
    this.me = null;
    this.httpClient.get(Settings.LOGOUT_URL).toPromise().then((req) => console.dir(req), (error) => console.dir(error));
  }

  refreshToken() {
    const self = this;
    setInterval(() => {
      const token = localStorage.getItem('jwt');
      this.httpClient.post<any>(Settings.REFRESH_URL, { token }).pipe().subscribe(
        token_get => localStorage.setItem('jwt', token_get.token),
        error => this.alertService.setAlert({
          type: 'error',
          message: this.translateService.instant("__securityUnlogged")
        }));
    }, 1000 * 60 * 15);
  }

  verifyToken(): Observable<any> {
    const token = localStorage.getItem('jwt');
    return this.httpClient.post<any>(Settings.VERIFY_URL, { token });
  }


  checkToken(): void {
    this.verifyToken().pipe().subscribe(
      req => this.refreshToken(),
      error => {
        this.alertService.setAlert({
          type: 'warning',
          message: this.translateService.instant("__inactivityUnlogged")
        });
        this.logout();
        this.router.navigate([ 'login' ]);
      }
    );
  }

  saveToken(token: any): void {
    localStorage.setItem('jwt', token.token);
  }

  removeToken(): void {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
  }

  resetPassword(email: string): Promise<any> {
    return this.httpClient.post(Settings.RESET_PASSWORD, { email }).toPromise();
  }

  confirmPassword(newPassword: string, confirmPassword: string, uid: string, token: string): Promise<any> {
    const body = {
      new_password1: newPassword,
      new_password2: confirmPassword,
      uid: uid,
      token: token
    };
    return this.httpClient.post(Settings.CONFIRM_PASSWORD, body).toPromise();
  }

  newPassword(current_password: string, new_password: string): Observable<any> {
    return this.httpClient.put(Settings.USERS, { current_password, new_password });
  }

  getMyInitialPath(): string {
    let initialPath = 'error';
    if (this.me.isClient()) {
      initialPath = `app/client/${this.me.client.id}/`;
    } else if (this.me.isPartner()) {
      initialPath = `app/partner/${this.me.partner.id}/`;
    } else if (this.me.isAdmin()) {
      initialPath = 'app/admin/';
    }
    return initialPath;
  }

  checkIsLogged(): boolean {
    const userInLocalStorage = Boolean(localStorage.getItem('jwt')) && Boolean(localStorage.getItem('user'));
    if (userInLocalStorage || !this.me) {
      this.loadUser();
    }
    return Boolean(this.me);
  }

  loadUser(): User {
    if (localStorage.getItem('user') && !this.me) {
      const json_user = JSON.parse(localStorage.getItem('user'));
      this.setUser(json_user.name, json_user.role, json_user.role_id, json_user.permissions);
      return this.me;
    } else if (this.me) {
      return this.me;
    }
  }

  setUser(username: string, role: string, role_id: number, permissions: string[]): User {
    this.me = User.fromData(username, role, role_id, permissions);
    return this.me;
  }

  saveUser(): void {
    localStorage.setItem('user', JSON.stringify(this.me));
  }

  checkHasPermissions(group: string): boolean {
    if (group === undefined ||
      (group === User.Admin && this.me.isAdmin()) ||
      (group === User.Partner && (this.me.isPartner() || this.me.isAdmin())) ||
      (group === User.Client && (this.me.isClient() || this.me.isPartner() || this.me.isAdmin()))) {
      return true;
    } else {
      this.router.navigate([ this.getMyInitialPath() ]);
    }
  }

  hasPartnerPermission() {
    return this.me.isPartner() || this.me.isAdmin();
  }
}
